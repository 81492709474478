.container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 0 10px;
    padding-left: 11vw;
    padding-top: 80px;
    gap: 20px;
}

p {
    padding: 10px;
    font-weight: 300;
}

.user_container {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    padding-left: 11vw;
    padding-top: 80px;
    gap: 20px;
}

/* @media (max-width: 768px) {
    .container {
        width: 50vw;
    }
} */

@media (max-width: 768px) {
    .container {
        align-items: center;
        justify-content: center;
        padding: 0;
        padding: 10px;
        padding-top: 80px;
        display: flex;
        flex-direction: column;
    }

    .user_container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        padding-top: 30px;
    }
}