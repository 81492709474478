.container {
    left: 0;
    padding: 0;
    width: 10vw;
    /* height: 99vh; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #011621;
    justify-content: start;
    align-items: start;
    gap: 3px;
    position: fixed;
    z-index: 2;
    overflow-y: auto;
}

.logo {
    width: 10vw;
    height: 20vh;
}

.icon {
    width: 25px;
    height: 25px;
    padding-left: 10px;
}

.container div {
    /* padding: 10px; */
    width: 9vw;
    display: flex;
    flex-direction: column;
}

.container .link {
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: start;
    gap: 10px;
    padding: 10px 0;
    width: 10vw;
    text-decoration: none;
    color: #2c9961;
    font-size: 18px;
    font-weight: 400;
}

.container .link:hover {
    /* background: #2c9961; */
    /* color: #011621; */
    /* width: 9vw; */
    cursor: pointer;
    /* transform: scale(1.2); */
    /* font-weight: bold; */
    background-color: rgba(110, 134, 116, 0.6);
    box-shadow: 0px 0px 20px rgb(36, 247, 36);
}

.activelink {
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: start;
    gap: 10px;
    padding: 10px 0px;
    width: 10vw;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    background: #2c9961;
    color: #011621;
}

.link_container {
    display: flex;
    flex-direction: column;
}

.miniLink {
    padding: 10px 0;
    text-align: center;
    width: 10vw;
    text-decoration: none;
    color: #2c9961;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #939393
}

.miniLink:hover {
    background: #8e94ff;
    color: #011621;
    /* width: 9vw; */
    cursor: pointer;
}

.activeMinilink {
    padding: 10px 0;
    text-align: center;
    width: 10vw;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    background: #cbceff;
    color: #000;
}

.menu_icon {
    padding: 15px 10px;
    position: absolute;
    z-index: 3;
}

@media (max-width: 768px) {
    .container {
        /* Mobile styles */
        width: 50vw;
    }

    .logo {
        width: 50vw;
    }

    .link {
        width: 50vw;
    }

    .activelink {
        width: 50vw;
    }

    .miniLink {
        width: 50vw;
    }

    .activeMinilink {
        width: 50vw;
    }
}