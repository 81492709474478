body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Roboto',
    monospace;
}

* {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
}

label {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #2c9961;
  justify-content: start;
  width: max-content;
}

img {
  width: 50px;
  height: 50px;
}

textarea {
  border: none;
  border-radius: 15px;
  box-shadow: 0px 1px 10px #939393;
  padding: 10px;
}

input {
  border: 1px solid #939393;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px;
}

select {
  width: 15vw;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #939393;
  border-radius: 8px;
  background-color: #f1f1f1;
}

.table-title {
  font-size: 15px;
}

.table-item {
  height: 10px;
}

.icon {
  width: 20px;
  height: 20px;
}

.dashboard-card {
  box-shadow: 0px 1px 10px #939393;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: center;
  width: 20vw;
  /* padding: 10px; */
  color: #000;
  text-decoration: none;
}

.dashboard-card:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
  transition: ease-in-out 330ms;
}

.dashboard-card h3 {
  padding: 10px;
}

.divider-dashboard {
  width: 20vw;
  border-bottom: 1px solid #d3d3d3;
}

.divider {
  /* width: 20vw; */
  border: 1px solid #000;
  /* padding: 100px; */
  margin: 20px 0px;
  /* padding-bottom: 20px; */
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  padding: 5px 30px;
  border: none;
  background-color: #2c9961;
  color: #fff;
  font-size: 14px;
  box-shadow: 0px 1px 10px #939393;
  width: max-content;
}

.button:hover {
  transform: scale(1.1);
  transition: ease-in-out 330ms;
  /* font-weight: bold; */
}

.button-white {
  text-decoration: none;
  border-radius: 5px;
  padding: 5px 15px;
  border: none;
  background-color: #fff;
  color: #2c9961;
  font-size: 18px;
  box-shadow: 0px 1px 10px #939393;
}

.button-white:hover {
  transform: scale(1.1);
  transition: ease-in-out 330ms;
  font-weight: bold;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.line-start {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
}

.flex-space-between {
  display: flex;
  /* gap: 20px; */
  justify-content: space-between;
  max-width: 89vw;
  /* background-color: #000; */
}

.hover:hover {
  background: rgba(28, 33, 125, 0.39);
}

.calendar-label {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
  color: #2c9961;
}

.calendar {
  width: max-content;
  border: 2px solid #939393;
  border-radius: 8px;
  padding: 5px 15px;
  color: #000;
}

.calendar:hover {
  border: 2px solid #c5c8ff;
  transform: scale(1.1);
  transition: ease-in-out 330ms;
  background-color: #2c9961;
  color: #fff;
}

.calendar-div {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 1px 10px #d3d3d3;
  border-radius: 12px;
}

.line-three {
  display: grid;
  grid-template-columns: auto auto auto;
}

.search {
  border: 1px solid #939393;
  background: #f5f5f5;
  border-radius: 16px;
  padding: 10px;
  font-size: 16px;
  width: 20vw;
}

.button_container {
  display: flex;
  gap: 20px;
  justify-content: end;
  align-items: center;
}

.abs {
  width: 100vw;
  height: 100vh;
  max-height: 200vh;
  /* height: 200vh; */
  position: absolute;
  height: 150vh;
  /* padding: 100px; */
  z-index: 10;
}

.blur_container {
  top: 0;
  left: 0;
  width: 99.2vw;
  min-height: 100vh;
  height: 150vh;
  max-height: max-content;
  backdrop-filter: blur(10px);
  position: absolute;
  background-color: #ffffff21;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 2000;
  /* padding: 50px; */
}

.modal {
  z-index: 2000;
  margin: 100px;
  padding: 50px;
  width: 40vw;
  height: max-content;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 1px 10px #939393;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: start;
  gap: 30px;
}

.two-line {
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loader-container {
  width: 89vw;
  height: 88vh;
  margin-left: 10vw;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

/* .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #FF3D00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}  */

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  /* background: rgba(0, 0, 0, alpha); */
}

.loader::after,
.loader::before {
  content: '';
  width: 80px;
  height: 80px;
  border: 2px solid #c5c8ff;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: rotation 2s ease-in-out infinite;
}

.loader::after {
  border-color: #3640ff;
  animation-delay: 1s;
}

.isFuture {
  border-left: 2px solid rgb(255, 161, 161);
  padding-left: 10px;
  /* border-spacing: 10px; */
  /* width: 2px; */
}

.isFutureBlue {
  border-left: 2px solid rgb(0, 202, 212);
  padding-left: 10px;
  /* border-spacing: 10px; */
  /* width: 2px; */
}

.green-border {
  border: 1px solid #2c9961;
  background-color: rgba(195, 237, 195, 0.619);
  color: #2c9961;
  border-radius: 10px;
  padding: 5px 10px;
}

.blue-border {
  border: 1px solid blue;
  background-color: rgba(109, 242, 252, 0.619);
  color: blue;
  border-radius: 10px;
  padding: 5px 10px;
}

.red-border {
  border: 1px solid red;
  background-color: rgba(255, 213, 213, 0.619);
  color: red;
  border-radius: 10px;
  padding: 5px 10px;
}

.full-green {
  /* border: 1px solid #2c9961; */
  background-color: #2c9961;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
}

.full-red {
  /* border: 1px solid #2c9961; */
  background-color: #fe9898;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
}

.full-blue {
  /* border: 1px solid #2c9961; */
  background-color: rgb(73, 167, 255);
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
}

.full-yellow {
  /* border: 1px solid #2c9961; */
  background-color: #fcce4e;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
}

.back {
  font-size: 11px;
  background-color: #f0f0f0;
  padding: 0;
  margin: 5px 0;
  border-radius: 8px;
  border: 1px solid rgb(46, 247, 46);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 760px) {
  .loader-container {
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
  }

  .dashboard-card {
    width: 95vw;
  }

  input {
    min-width: 40vw;
  }

  label {
    min-width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  select {
    width: 50vw;
  }

  .abs {
    padding: 0;
  }

  .blur_container {
    padding: 0;
    bottom: 0;
    /* height: 10vh; */
  }

  .modal {
    width: 70vw;
    bottom: 0;
  }

  .button {
    font-size: 18px;
  }

  .line-three {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .line-start {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .flex-space-between {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .space-between {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }
}